<template>
  <div v-if="rendered" class="home">
    <div
      v-if="this.client.service_level === 'standard'"
      class="pt-10 flex flex-col"
    >
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div class="flex">
          <i class="py-3.5 pl-2 fa-solid fa-circle-info text-shiftlyorange"></i>
          <p class="py-2.5 px-2 text-shiftlydarkblue">
            If you would like to change your service level, click
            <router-link
              to="/profile"
              class="underline text-blue-600 hover:text-blue-800"
              >here</router-link
            >.
          </p>
        </div>
      </div>
    </div>
    <h2
      class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-10 text-shiftlydarkblue"
    >
      Welcome {{ this.fullUserName }}
    </h2>
    <h3
      v-if="this.client.status === 'registered'"
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      We see your trading profile has not been completed yet. Please complete
      the following sections to start trading.
    </h3>
    <h3
      v-if="this.client.status === 'signed_up'"
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      Thank you for providing all your information. Our support team is busy
      processing your application.
    </h3>
    <h3
      v-if="
        this.client.status === 'onboarded' && this.service_level === 'standard'
      "
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      You're all set to trade, happy trading!
    </h3>
    <h3
      v-if="
        this.client.status === 'onboarded' && this.service_level === 'premium'
      "
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      You’re all set to trade, please follow the instructions in the Start
      Trading email we’ve sent you to ensure your trading commences.
    </h3>
    <h3
      v-if="this.client.status === 'archived'"
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      According to our records you have not traded in a while.
      <br />
      Please contact Shiftly support for assistance
      <br />
      <a href="mailto:support@shiftly.co.za"
        ><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a
      >
    </h3>
    <div
      v-if="this.client.status === 'registered' && this.service_level !== ''"
      class="py-10 flex flex-col"
    >
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div class="flex flex-wrap py-5 justify-center" style="cursor: auto">
          <div
            v-if="personalInformationHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i class="fa-solid fa-user text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Personal <br />
              Information
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/personal-information">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="contactDetailsHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-address-card text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Contact <br />
              Details
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/contact-details">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="ficaDocumentsHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
            style="cursor: auto"
          >
            <div class="mb-4">
              <i class="fa-solid fa-file text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              FICA <br />
              Documents
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/fica-documents">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="employmentInformationHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i class="fa-solid fa-briefcase text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Employment <br />
              Information
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/employment-information">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="bankDetailsHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i class="fa-solid fa-landmark text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Bank <br />
              Details
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/bank-details">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="sourceofFundsHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i class="fa-solid fa-wallet text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Source <br />
              of Funds
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/source-of-funds">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="allowanceInformationHide === false"
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-file-invoice-dollar text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Allowance <br />
              Information
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/allowance-information">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>

          <div
            v-if="
              eFilingInformationHide === false &&
              this.service_level === 'premium'
            "
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-money-bill-wave text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              eFiling <br />
              Information
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/efiling-information">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
          <div
            v-if="
              localAssetsAndLiabilitiesHide === false &&
              this.service_level === 'premium'
            "
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-plane-arrival text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Local Assets <br />
              and Liabilities
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/local-assets-and-liabilities">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
          <div
            v-if="
              foreignAssetsAndLiabilitiesHide === false &&
              this.service_level === 'premium'
            "
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-plane-departure text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-2 xl:mb-3 text-shiftlydarkblue">
              Foreign Assets <br />
              and Liabilities
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/foreign-assets-and-liabilities">
                <button
                  class="w-28 py-4 px-5 mb-5 mt-0 xl:my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
          <div
            v-if="
              incomeAndExpensesHide === false &&
              this.service_level === 'premium' &&
              (this.credit_choice === 'top_up' ||
                this.credit_choice === 'credit')
            "
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-4">
              <i
                class="fa-solid fa-file-invoice text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-3 text-shiftlydarkblue">
              Income and <br />
              Expenses
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/profile/income-and-expenses">
                <button
                  class="w-28 py-4 px-5 my-5 bg-shiftlyblue text-white rounded-md hover:bg-shiftlydarkblue"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.client.status === 'signed_up'" class="py-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div
          class="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl sm:px-8"
        >
          <div class="grid gap-8 row-gap-0 sm:grid-cols-4 lg:grid-cols-6">
            <div class="relative text-center sm:col-span-2">
              <div
                class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-shiftlyblue sm:w-20 sm:h-20"
              >
                <i class="fa-solid fa-laptop-file text-3xl text-white"></i>
              </div>
              <h6 class="mb-2 text-xl text-shiftlydarkblue font-extrabold">
                Step 1: Application Processing
              </h6>
              <p
                class="max-w-md mb-7 sm:mb-3 text-md text-shiftlydarkblue sm:mx-auto"
              >
                We'll reach out to you if we require any further information.
                <span
                  v-if="
                    this.service_level === 'premium' &&
                    this.credit_choice !== 'own'
                  "
                  >You will receive an email containing your credit facility
                  agreement for you to sign.</span
                >
              </p>
              <div
                class="top-0 right-0 flex items-center justify-center sm:h-24 sm:-mr-8 sm:absolute"
              >
                <i
                  class="fa-solid fa-arrow-right text-3xl text-shiftlyorange transform rotate-90 sm:rotate-0 sm:mr-0.5"
                ></i>
              </div>
            </div>
            <div class="relative text-center sm:col-span-2">
              <div
                class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-shiftlyblue sm:w-20 sm:h-20"
              >
                <i class="fa-solid fa-landmark text-3xl text-white"></i>
              </div>
              <h6 class="mb-2 text-xl text-shiftlydarkblue font-extrabold">
                Step 2: Bank Account Opening
              </h6>
              <p
                class="max-w-md mb-7 sm:mb-3 text-md text-shiftlydarkblue sm:mx-auto"
              >
                You'll receive a ZohoSign envelope via email containing
                pre-populated bank account opening forms for you to sign.
              </p>
              <div
                class="top-0 right-0 flex items-center justify-center sm:h-24 lg:-mr-8 lg:absolute sm:hidden lg:flex"
              >
                <i
                  class="fa-solid fa-arrow-right text-3xl text-shiftlyorange transform rotate-90 lg:rotate-0 sm:mr-0.5"
                ></i>
              </div>
            </div>
            <div class="relative text-center sm:col-span-2">
              <div
                class="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-shiftlyblue sm:w-20 sm:h-20"
              >
                <i class="fa-solid fa-face-grin-beam text-3xl text-white"></i>
              </div>
              <h6 class="mb-2 text-xl text-shiftlydarkblue font-extrabold">
                Step 3: Start Trading
              </h6>
              <p
                class="max-w-md mb-7 sm:mb-3 text-md text-shiftlydarkblue sm:mx-auto"
              >
                Once your bank account is open you'll receive an email from us
                with information on how to start trading.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        this.client.status === 'onboarded' && this.service_level === 'standard'
      "
      class="py-10 flex flex-col"
    >
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div
          class="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-12 lg:px-8"
        >
          <div class="grid gap-1 row-gap-10 lg:grid-cols-2">
            <div class="pb-6 lg:pb-6 lg:pr-8">
              <div class="text-left mb-6">
                <h3 class="text-lg text-shiftlyblue">Trade Process Overview</h3>
                <p>
                  Please refer to the Start Trading email we've sent you for
                  in-depth instructions on how to start trading.
                </p>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div
                    class="flex items-center justify-center w-12 h-12 border border-shiftlyorange rounded-full"
                  >
                    <i class="fa-solid fa-1 text-shiftlyblue"></i>
                  </div>
                  <div class="w-px h-14 bg-shiftlyorange"></div>
                </div>
                <div class="pb-5 text-left text-shiftlydarkblue">
                  <p>
                    Ensure your FX bank account is funded and check our
                    <a
                      class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      href="https://t.me/shiftly_bot"
                      target="_blank"
                      >Telegram Bot</a
                    >
                    to decide if you'd like to book a trade.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div
                    class="flex items-center justify-center w-12 h-12 border border-shiftlyorange rounded-full"
                  >
                    <i class="fa-solid fa-2 text-shiftlyblue"></i>
                  </div>
                  <div class="w-px h-14 bg-shiftlyorange"></div>
                </div>
                <div class="pb-5 text-left text-shiftlydarkblue">
                  <p>
                    Book your trade
                    <router-link
                      class="underline text-blue-600 hover:text-blue-800"
                      to="/transact"
                      >here</router-link
                    >
                    before 08:30.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div
                    class="flex items-center justify-center w-12 h-12 border border-shiftlyorange rounded-full"
                  >
                    <i class="fa-solid fa-3 text-shiftlyblue"></i>
                  </div>
                  <div class="w-px h-14 bg-shiftlyorange"></div>
                </div>
                <div class="pb-5 text-left text-shiftlydarkblue">
                  <p>
                    Your Rands will be exchanged for foreign currency and the
                    arbitrage trade will execute.
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex flex-col items-center mr-4">
                  <div
                    class="flex items-center justify-center w-12 h-12 border border-shiftlyorange rounded-full"
                  >
                    <i class="fa-solid fa-check text-shiftlyblue"></i>
                  </div>
                </div>
                <div class="text-left text-shiftlydarkblue">
                  <p>
                    We'll return your capital and profits to your FX bank
                    account. Repeat the process until you've utilised your
                    desired amount of foreign capital allowances for the
                    calendar year.
                  </p>
                </div>
              </div>
            </div>
            <div class="relative">
              <img
                class="inset-0 w-full h-full scale-100 lg:scale-125"
                src="../assets/ShiftlyFlow.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        this.client.status === 'onboarded' && this.service_level === 'premium'
      "
      class="py-10 flex flex-col"
    >
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div
          class="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-12 lg:px-8"
        >
          <div class="relative lg:h-72 xl:h-80 2xl:h-96">
            <img
              class="inset-0 w-full h-full scale-100 lg:scale-125"
              src="../assets/ShiftlyFlow.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";
import moment from "moment";
import { mapState, mapActions } from "pinia";
import { useApiStore } from "../stores/apiStore";
import * as Sentry from "@sentry/vue";

export default {
  name: "HomeDashboard",
  data() {
    return {
      fullUserName: "",
      shortUserName: "",
      rendered: false,
      personalInformationHide: false,
      contactDetailsHide: false,
      ficaDocumentsHide: false,
      employmentInformationHide: false,
      bankDetailsHide: false,
      sourceofFundsHide: false,
      eFilingInformationHide: false,
      allowanceInformationHide: false,
      localAssetsAndLiabilitiesHide: false,
      foreignAssetsAndLiabilitiesHide: false,
      incomeAndExpensesHide: false,
      client: [],
      clientStatus: {
        status: "",
      },
      clientConsent: {
        fais_consent: "",
      },
      clientDecline: {
        fais_decline: "",
      },
      service_level: "",
      credit_choice: "",
      referral_active: false,
      requiredFields: [],
      documents: [],
      specifiedDate: this.createSpecifiedDate(),
      currentDate: new Date(),
      targetYear: "",
      assets_liabilities_year: "",
    };
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client.service_level === "") {
        this.$router.push("/profile");
      }
      if (this.client.fais_consent === null) {
        this.faisConsentPopUp();
      }
      this.clientStatus.status = this.client.status;
      TokenService.setClientStatus(this.client.status);
      EventBus.dispatch("updateClientStatus");
      this.service_level = this.client.service_level;
      TokenService.setServiceLevel(this.client.service_level);
      EventBus.dispatch("updateServiceLevel");
      if (this.service_level === "premium") {
        this.credit_choice = this.client.credit_choice;
        TokenService.setCreditChoice(this.client.credit_choice);
        EventBus.dispatch("updateCreditChoice");
      }
      this.referral_active = this.client.referral_active;
      TokenService.setReferralActive(this.client.referral_active);
      EventBus.dispatch("updateReferralActive");
      this.personalInformationHide = this.checkPersonalInformationComplete();
      this.contactDetailsHide = this.checkContactDetailsComplete();
      this.checkFicaDocumentsComplete().then((result) => {
        this.ficaDocumentsHide = result;
        this.rendered = true;
      });
      this.checkEmploymentInformationComplete().then((result) => {
        this.employmentInformationHide = result;
      });
      this.bankDetailsHide = this.checkBankDetailsComplete();
      this.checkSourceofFundsComplete().then((result) => {
        this.sourceofFundsHide = result;
      });
      this.checkAllowanceInformationComplete().then((result) => {
        this.allowanceInformationHide = result;
      });
      if (this.client.service_level === "premium") {
        this.eFilingInformationHide = this.checkEFilingInformationComplete();
        this.checkLocalAssetsandLiabilitiesComplete().then((result) => {
          this.localAssetsAndLiabilitiesHide = result;
        });
        this.checkForeignAssetsandLiabilitiesComplete().then((result) => {
          this.foreignAssetsAndLiabilitiesHide = result;
        });
        if (this.credit_choice !== "own") {
          this.checkIncomeAndExpensesComplete().then((result) => {
            this.incomeAndExpensesHide = result;
          });
        }
      }
      this.fullUserName =
        client.data.preferred_name + " " + client.data.surname;
      this.shortUserName =
        client.data.preferred_name.charAt(0) +
        client.data.surname.match(/(\b\S)?/g).join("");
      TokenService.setUsername(this.fullUserName, this.shortUserName);
      EventBus.dispatch("updateUserName");
      if (
        this.client.service_level != "" &&
        this.client.status === "onboarded" &&
        this.client.fais_consent != null
      ) {
        this.checkAllowanceInformationYear();
      }
    });
  },
  created() {
    document.title = "Shiftly Client Portal - Home";
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
    this.getAssetLiabilityYear();
  },
  updated() {
    this.checkSignUpComplete();
  },
  computed: {
    ...mapState(useApiStore, [
      "loggedIn",
      "token",
      "personalInformation",
      "contactDetails",
      "ficaDocuments",
      "employmentInformation",
      "bankDetails",
      "sourceofFunds",
      "eFilingInformation",
      "allowanceInformation",
      "assetsAndLiabilities",
      "incomeAndExpenses",
    ]),
  },
  watch: {
    personalInformation(newPersonalInformation) {
      this.personalInformationHide = newPersonalInformation;
    },
    contactDetails(newcontactDetails) {
      this.contactDetailsHide = newcontactDetails;
    },
    ficaDocuments(newFicaDocuments) {
      this.ficaDocumentsHide = newFicaDocuments;
    },
    employmentInformation(newEmploymentInformation) {
      this.employmentInformationHide = newEmploymentInformation;
    },
    bankDetails(newBankDetails) {
      this.bankDetailsHide = newBankDetails;
    },
    sourceofFunds(newSourceofFunds) {
      this.sourceofFundsHide = newSourceofFunds;
    },
    eFilingInformation(neweFilingInformation) {
      this.eFilingInformationHide = neweFilingInformation;
    },
    allowanceInformation(newAllowanceInformation) {
      this.allowanceInformationHide = newAllowanceInformation;
    },
    localAssetsAndLiabilities(newLocalAssetsAndLiabilities) {
      this.localAssetsAndLiabilitiesHide = newLocalAssetsAndLiabilities;
    },
    foreignAssetsAndLiabilities(newForeignAssetsAndLiabilities) {
      this.foreignAssetsAndLiabilitiesHide = newForeignAssetsAndLiabilities;
    },
    incomeAndExpenses(newIncomeAndExpenses) {
      this.incomeAndExpensesHide = newIncomeAndExpenses;
    },
  },
  methods: {
    ...mapActions(useApiStore, ["patchClientStatus"]),
    showError(error) {
      Sentry.captureException(error);
      this.$swal({
        icon: "error",
        title: "Something went wrong!",
        text: "Please contact Shiftly support for assistance",
        footer:
          '<a href="mailto:support@shiftly.co.za"><i class="fa-solid fa-envelope pr-2"></i>support@shiftly.co.za</a>',
      });
    },
    createSpecifiedDate() {
      var specifiedDate = new Date();
      specifiedDate.setDate(15);
      specifiedDate.setMonth(11);
      specifiedDate.setHours(0, 0, 1);
      return specifiedDate;
    },
    checkAllowanceInformationYear() {
      var currentDateYear = this.currentDate.getFullYear();
      this.targetYear =
        this.currentDate < this.specifiedDate
          ? currentDateYear
          : currentDateYear + 1;

      ClientService.getAllowances().then((allowances) => {
        if (allowances.data.count > 0) {
          var redirect = !Object.values(allowances.data.results).some(
            (value) => value.year === this.targetYear
          );

          if (redirect) {
            this.continuationPopUp();
          } else {
            if (this.client.service_level === "premium") {
              this.checkLocalAssetLiabilityYear();
            }
          }
        }
      });
    },
    checkLocalAssetLiabilityYear() {
      ClientService.getAssetsandLiabilities("local").then(
        (assetsliabilities) => {
          if (assetsliabilities.data.count > 0) {
            var redirect = !Object.values(assetsliabilities.data.results).some(
              (value) => value.year === this.assets_liabilities_year
            );

            if (redirect) {
              this.$swal(
                "Missing Information!",
                "Please complete your Local Asset and Liabilities Information for " +
                  this.assets_liabilities_year +
                  " and Submit.",
                "info"
              );
              this.$router.push("/profile/local-assets-and-liabilities");
            } else {
              this.checkForeignAssetLiabilityYear();
            }
          }
        }
      );
    },
    checkForeignAssetLiabilityYear() {
      ClientService.getAssetsandLiabilities("foreign").then(
        (assetsliabilities) => {
          if (assetsliabilities.data.count > 0) {
            var redirect = !Object.values(assetsliabilities.data.results).some(
              (value) => value.year === this.assets_liabilities_year
            );

            if (redirect) {
              this.$swal(
                "Missing Information!",
                "Please complete your Foreign Asset and Liabilities Information for " +
                  this.assets_liabilities_year +
                  " and Submit.",
                "info"
              );
              this.$router.push("/profile/foreign-assets-and-liabilities");
            }
          }
        }
      );
    },
    getAssetLiabilityYear() {
      let currentDate = new Date();
      let currentYear = new Date().getFullYear();

      currentDate = moment(currentDate).format("YYYY-MM-DD");

      if (currentDate >= currentYear + "-03-01") {
        this.assets_liabilities_year = currentYear;
      } else {
        this.assets_liabilities_year = currentYear - 1;
      }
    },
    faisConsentPopUp() {
      this.$swal({
        title: "Consent",
        html: "In order to remain compliant with the FAIS Act, from 1 December 2023 our arbitrage service will be provided by Shiftly FX (Pty) Ltd, our licensed Financial Services Provider. <br><br> By clicking “Accept” below you consent to the above and accept the Shiftly FX (Pty) Ltd <a class='underline text-blue-600 hover:text-blue-800' href='https://shiftly.co.za/documents/?terms-and-conditions' target='_blank'>Terms and Conditions</a> and <a class='underline text-blue-600 hover:text-blue-800' href='https://shiftly.co.za/documents/?privacy-policy' target='_blank'>Privacy Policy</a>.",
        icon: "info",
        iconColor: "#ffbb11",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Decline",
        confirmButtonText: "Accept",
        reverseButtons: "true",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.patchFAISConsentToApi();
        } else if (result.isDismissed) {
          this.patchFAISDeclineToApi();
        }
      });
    },
    continuationPopUp() {
      this.$swal({
        title: "Action Required!",
        html:
          "Please provide the necessary information to ensure your trading seamlessly continues in " +
          this.targetYear +
          ".",
        icon: "info",
        showCancelButton: true,
        cancelButtonColor: "#ffbb11",
        cancelButtonText: "Remind Me Later",
        confirmButtonText: "Submit Now",
        reverseButtons: "true",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push("/trade-continuation");
        } else if (result.isDismissed) {
          if (this.currentDate.getFullYear() == this.targetYear) {
            EventBus.dispatch("logout");
          }
        }
      });
    },
    areRequiredFieldsPresent(fields, object) {
      for (let field of fields) {
        if (
          !Object.prototype.hasOwnProperty.call(object, field) ||
          object[field] === null ||
          object[field] === ""
        ) {
          return false;
        }
      }
      return true;
    },
    checkPersonalInformationComplete() {
      this.requiredFields = [
        "id_number",
        "title",
        "initials",
        "first_names",
        "surname",
        "preferred_name",
        "gender",
        "race",
        "marital_status",
        "date_of_birth",
        "country_of_birth",
        "nationality",
        "has_dual_citizenship",
        "net_worth",
        "tax_residency1",
        "tax_number1",
      ];

      return this.areRequiredFieldsPresent(this.requiredFields, this.client);
    },
    checkContactDetailsComplete() {
      this.requiredFields = [
        "primary_email",
        "cellphone_number",
        "address_line1",
        "address_city",
        "address_code",
        "address_country",
        "address_province",
      ];
      if (this.areRequiredFieldsPresent(this.requiredFields, this.client)) {
        if ("contact_details_show" in this.client.portal_state) {
          if (this.client.portal_state.contact_details_show) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    checkFicaDocumentsComplete() {
      return new Promise((resolve) => {
        ClientService.getDocumentsCategory("proof_of_identity").then(
          (documents) => {
            if (documents.data.count > 0) {
              ClientService.getDocumentsCategory("proof_of_address").then(
                (documents) => {
                  if (documents.data.count > 0) {
                    if (this.client.portal_state.fica_documents_show) {
                      resolve(false);
                    } else {
                      resolve(true);
                    }
                  } else {
                    resolve(false);
                  }
                }
              );
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    checkEmploymentInformationComplete() {
      if (this.client.employment_status === "employed") {
        this.requiredFields = [
          "employment_status",
          "gross_monthly_income",
          "employer_name",
          "employer_industry",
          "occupation",
          "designation",
          "employment_date",
          "employer_telephone_number",
        ];
      } else if (this.client.employment_status === "self-employed") {
        this.requiredFields = [
          "employment_status",
          "gross_monthly_income",
          "employer_industry",
          "occupation",
        ];
      } else {
        this.requiredFields = ["employment_status", "gross_monthly_income"];
      }

      if (
        this.client.service_level === "standard" ||
        (this.client.service_level === "premium" &&
          this.client.credit_choice === "own")
      ) {
        return Promise.resolve(
          this.areRequiredFieldsPresent(this.requiredFields, this.client)
        );
      } else {
        if (
          this.areRequiredFieldsPresent(this.requiredFields, this.client) ===
          true
        ) {
          return new Promise((resolve) => {
            ClientService.getDocumentsCategory("source_of_income").then(
              (documents) => {
                if (documents.data.count > 0) {
                  if (this.client.employment_status === "employed") {
                    Object.entries(documents.data.results).forEach(
                      ([, value]) => {
                        if (value.type === "payslip") {
                          resolve(true);
                        } else {
                          resolve(false);
                        }
                      }
                    );
                  } else {
                    Object.entries(documents.data.results).forEach(
                      ([, value]) => {
                        if (value.type === "bank_statement") {
                          resolve(true);
                        } else {
                          resolve(false);
                        }
                      }
                    );
                  }
                } else {
                  resolve(false);
                }
              }
            );
          });
        } else {
          return Promise.resolve(false);
        }
      }
    },
    checkBankDetailsComplete() {
      this.requiredFields = [
        "capital_distribution_account",
        "profit_distribution_account",
      ];

      return this.areRequiredFieldsPresent(this.requiredFields, this.client);
    },
    checkSourceofFundsComplete() {
      this.requiredFields = ["own_funds"];

      if (
        this.client.service_level === "premium" &&
        this.client.credit_choice === "credit"
      ) {
        return Promise.resolve(
          this.areRequiredFieldsPresent(this.requiredFields, this.client)
        );
      } else {
        if (
          this.areRequiredFieldsPresent(this.requiredFields, this.client) ===
          true
        ) {
          return new Promise((resolve) => {
            ClientService.getDocumentsCategory("source_of_funds").then(
              (documents) => {
                if (documents.data.count > 0) {
                  if (this.client.portal_state.source_of_funds_show) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                } else {
                  resolve(false);
                }
              }
            );
          });
        } else {
          return Promise.resolve(false);
        }
      }
    },
    checkEFilingInformationComplete() {
      this.requiredFields = [
        "efiling_username",
        "efiling_password",
        "ait_trust",
        "ait_shareholding",
        "ait_loan",
      ];

      return this.areRequiredFieldsPresent(this.requiredFields, this.client);
    },
    checkAllowanceInformationComplete() {
      return new Promise((resolve) => {
        ClientService.getAllowances().then((allowances) => {
          if (allowances.data.count > 0) {
            if (
              Object.values(allowances.data.results).some(
                (value) => value.year === new Date().getFullYear()
              )
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },
    checkLocalAssetsandLiabilitiesComplete() {
      return new Promise((resolve) => {
        ClientService.getAssetsandLiabilities("local").then(
          (assetsliabilities) => {
            if (assetsliabilities.data.count > 0) {
              Object.entries(assetsliabilities.data.results).forEach(
                ([, value]) => {
                  if (value.year === this.assets_liabilities_year) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                }
              );
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    checkForeignAssetsandLiabilitiesComplete() {
      return new Promise((resolve) => {
        ClientService.getAssetsandLiabilities("foreign").then(
          (assetsliabilities) => {
            if (assetsliabilities.data.count > 0) {
              Object.entries(assetsliabilities.data.results).forEach(
                ([, value]) => {
                  if (value.year === this.assets_liabilities_year) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                }
              );
            } else {
              resolve(false);
            }
          }
        );
      });
    },
    checkIncomeAndExpensesComplete() {
      return new Promise((resolve) => {
        ClientService.getIncomeandExpenses().then((incomeandexpenses) => {
          if (incomeandexpenses.data.count > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    checkSignUpComplete() {
      if (
        this.client.status === "registered" &&
        this.personalInformationHide &&
        this.contactDetailsHide &&
        this.ficaDocumentsHide &&
        this.employmentInformationHide &&
        this.bankDetailsHide &&
        this.allowanceInformationHide &&
        ((this.client.service_level === "standard" && this.sourceofFundsHide) ||
          (this.client.service_level === "premium" &&
            this.client.credit_choice === "own" &&
            this.sourceofFundsHide &&
            this.eFilingInformationHide &&
            this.localAssetsAndLiabilitiesHide &&
            this.foreignAssetsAndLiabilitiesHide) ||
          (this.client.service_level === "premium" &&
            this.client.credit_choice === "top_up" &&
            this.sourceofFundsHide &&
            this.eFilingInformationHide &&
            this.localAssetsAndLiabilitiesHide &&
            this.foreignAssetsAndLiabilitiesHide &&
            this.incomeAndExpensesHide) ||
          (this.client.service_level === "premium" &&
            this.client.credit_choice === "credit" &&
            this.eFilingInformationHide &&
            this.localAssetsAndLiabilitiesHide &&
            this.foreignAssetsAndLiabilitiesHide &&
            this.incomeAndExpensesHide))
      ) {
        this.patchStatusToApi();
      }
    },
    //Api Submit
    patchStatusToApi() {
      //Sending payload to the backend
      this.clientStatus.status = "signed_up";
      var payload = { url: this.client.url, values: this.clientStatus };
      this.patchClientStatus(payload).then(
        () => {
          this.client.status = "signed_up";
          TokenService.setClientStatus("signed_up");
          EventBus.dispatch("updateClientStatus");
        },
        (error) => {
          if (error.response.status == "400") {
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.showError(error);
          }
        }
      );
    },
    //Api Submit FAIS Consent
    patchFAISConsentToApi() {
      //Sending consent to the backend
      this.clientConsent.fais_consent = new Date();
      var payload = { url: this.client.url, values: this.clientConsent };
      this.patchClientStatus(payload).then(
        () => {
          if (this.client.service_level === "") {
            this.$router.push("/profile");
          } else {
            this.$router.push("/home");
          }
        },
        (error) => {
          if (error.response.status == "400") {
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.showError(error);
          }
        }
      );
    },
    //Api Submit FAIS Decline
    patchFAISDeclineToApi() {
      //Sending decline to the backend
      this.clientDecline.fais_decline = new Date();
      var payload = { url: this.client.url, values: this.clientDecline };
      this.patchClientStatus(payload).then(
        () => {
          EventBus.dispatch("logout");
        },
        (error) => {
          if (error.response.status == "400") {
            this.showError(error);
          } else {
            if (error.response && error.response.status == "401") {
              EventBus.dispatch("logout");
            }
            this.showError(error);
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
